import React, { Fragment, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';
import { filter, map, path } from 'ramda';
import _ from 'lodash';
import { convertCurrencySymbol, getGuestsCountForReservation, getRates, pluralize } from 'utils/helpers';
import { DESKTOP, LANDSCAPE_MODE_TABLETS, LAPTOP, MOBILE, TABLET } from 'constants/css/breakpoints';
import Gallery from 'App/components/Gallery/Gallery.component';
import ListingCarousel from 'App/components/ListingCarousel/ListingCarousel.component';
import { getInvoicePageUrl, getQuotesPageUrl } from 'utils/url.utils';
import { selectIsPaymentRequired } from 'redux/selectors/quoteSelector';
import { Amenities } from 'App/pages/Listing/components/Listing/Amenities';
import LangSelect from 'components/LangSelect/LangSelect.component';

import createStyles from '@guestyci/foundation/createStyles';
import t from '@guestyci/localize/t.macro';
import TextField from '@guestyci/foundation/TextField';
import { Collapse, Divider, Icon } from '@guestyci/atomic-design/dist/v2/components';
import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';
import { Button } from '@guestyci/arc';

import Layout from '../../../../components/Layout/new/Layout.component';
import ListingMap from '../ListingMap/ListingMap';
import Footer from '../Footer/Footer';
import { CloseButton, Listing, MapWrapper, Tag } from './Listing.styled';
import HouseRules from './HouseRules';

const AMENITIES_TO_SHOW = 6;

const useClasses = createStyles(({ spacer }) => ({
  lang: {
    top: 0,
    right: 0,
    minWidth: '100%',
  },
  header: {
    position: 'relative',
  },
  buttonWrapper: {
    position: 'absolute',
    top: 0,
    zIndex: 1,
    width: '100%',
  },
}));

const ListingComponent = ({ breakpoint, history, listing, params, quotes }) => {
  const [, isSAQGRFlowEnabled] = useFeatureToggle('RES-SAQ-GR-flow');
  const location = useLocation();
  const classes = useClasses();
  const [isAmenitiesCollapsed, setIsAmenitiesCollapsed] = useState(true);
  const [isSummaryCollapsed, setIsSummaryCollapsed] = useState(true);
  const { quotesId, listingId } = params;

  const expireAt = path(['expireAt'], quotes);
  const priceObj = path(
    ['0', 'price'],
    filter((res) => res.listing._id === listingId, path(['meta', 'reservations'], quotes) || []),
  );
  const reservation = filter(
    (res) => res.listing._id === listingId,
    path(['meta', 'reservations'], quotes) || [],
  )[0];
  const inquiry = path(['inquiry'], reservation);

  const price = path(['value'], priceObj);
  const currency = path(['currency'], priceObj);
  const propertyType = path(['propertyType'], listing);
  const publicDescription = path(['publicDescription'], listing);
  const ratePlans = path(['rates', 'ratePlans'], inquiry) || [];
  const currentCurrency = currency || ratePlans?.[0]?.quote.money.currency;
  const convertedCurrency = convertCurrencySymbol(currentCurrency);
  const estPayoutRange = useMemo(
    () => getRates(ratePlans, convertedCurrency),
    [ratePlans, convertedCurrency],
  );
  const hasEstPayout = estPayoutRange && estPayoutRange.includes('-');
  const payoutValue = !ratePlans?.length ? `${convertedCurrency}${price}` : estPayoutRange;
  const isPaymentRequired = useSelector(selectIsPaymentRequired);

  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const isPageReadOnly = urlSearchParams.get('readOnly') === 'true';
  const backUrl = urlSearchParams.get('backUrl');

  const payoutCaption = hasEstPayout ? t('Estimated payout') : t('Total price');

  const isMobile = breakpoint === MOBILE;
  const isTablet = breakpoint === TABLET;
  const isDesktop = breakpoint === DESKTOP;
  const isLaptop = breakpoint === LAPTOP;
  const isLandscapeMode = breakpoint === LANDSCAPE_MODE_TABLETS;

  const onClose = isMobile ? () => history.push(getQuotesPageUrl({ quotesId })) : undefined;

  const lat = path(['publishedAddress', 'lat'], listing) || path(['address', 'lat'], listing);
  const lng = path(['publishedAddress', 'lng'], listing) || path(['address', 'lng'], listing);
  const amenities = path(['amenities'], listing) || [];
  const description = path(['privateDescription', 'directions'], listing) || '';
  const fullAddress =
    path(['publishedAddress', 'fullAddress'], listing) || path(['address', 'fullAddress'], listing);
  const imagesAll = map(
    ({ original, thumbnail }) => original || thumbnail,
    path(['pictures'], listing) || [],
  );
  const images = [...imagesAll];
  images.push(images.shift());
  const locationDescription = path(['privateDescription', 'directions'], listing) || '';

  const guestsCount = getGuestsCountForReservation(reservation);
  const guestsCountText = pluralize(guestsCount, t('guest'), t('guests'));

  const bedCount = path(['bedrooms', 'count'], listing);
  const bedCountText = pluralize(bedCount, t('bed'), t('beds'));

  const bathroomCount = path(['bathrooms', 'count'], listing);
  const bathroomCountText = pluralize(bathroomCount, t('bathroom'), t('bathrooms'));

  const bedroomCount = path(['bedrooms', 'count'], listing);
  const bedroomCountText = pluralize(bedroomCount, t('bedroom'), t('bedrooms'));

  const tags = [propertyType, guestsCountText, bedCountText, bathroomCountText, bedroomCountText];
  const title = path(['title'], listing) || '';
  const showHouseRules = path(['showHouseRules'], quotes);
  const houseRules = path(['houseRules'], listing);

  // Get all values from publicDescription
  const allDescriptionValues = Object.values(publicDescription || {})
    .filter((value) => typeof value === 'string')
    .map((value) => value.split('.'))
    .flat()
    .filter((sentence) => sentence.trim().length > 0);

  const hasLongDescription = allDescriptionValues.length > 2;
  const [, hasLang] = useFeatureToggle('RES-SAQ-lang');

  return (
    <>
      <Layout.ContainerFluid>
        <div className={classes.header}>
          <div className={cn('arc-scope', classes.buttonWrapper)}>
            {isSAQGRFlowEnabled && (
              <a href={backUrl || getQuotesPageUrl({ quotesId })} className="text-decoration-none">
                <Button variant="secondary" onClick={_.noop}>
                  {t('Back')}
                </Button>
              </a>
            )}
          </div>
          <Layout.Logo />
          {hasLang && <LangSelect className={classes.lang} />}
        </div>

        {!!listing && !!quotes && (
          <Listing className="d-flex flex-1 flex-column overflow-hidden">
            <div className="w-100 pos-relative">
              {isMobile && (
                <>
                  <Gallery
                    images={images}
                    isMobile={isMobile}
                    isTablet={isTablet}
                    isLandscapeMode={isLandscapeMode}
                  />
                  <CloseButton
                    className="d-flex align-items-center justify-content-left pl-3"
                    onClick={onClose}
                  >
                    <Icon color="gray-dark" width={14} height={14} icon="BtnDeleteMobile" />
                  </CloseButton>
                </>
              )}
              <ListingCarousel
                images={images}
                showArrows={!isMobile}
                isMobile={isMobile}
                isTablet={isTablet}
                isLaptop={isLaptop}
                isLandscapeMode={isLandscapeMode}
              />
            </div>
            <div
              className={cn('d-flex flex-column w-100', {
                'pt-12 pb-12 pl-11 pr-11': isDesktop,
                'p-6': isTablet,
                'pt-6 pr-6 pl-6': isMobile,
              })}
            >
              <div className="d-flex flex-wrap justify-content-between pb--1">
                <TextField variant="h2">{title}</TextField>
                {!isMobile && (
                  <div className="d-flex align-items-baseline">
                    <TextField variant="h2">
                      {payoutValue}
                      &nbsp;
                    </TextField>
                    <div className="color-grayish-blue">{payoutCaption}</div>
                  </div>
                )}
              </div>
              <div className="font-size-lg pt--1">{fullAddress}</div>
              <Divider className="mt-6" />

              {/** Tags */}
              <div
                className={cn('d-flex flex-wrap pt-2', {
                  'flex-column': isMobile,
                })}
              >
                {tags.map((tag, index) =>
                  tag ? (
                    <Tag key={`tag-${index}`} className="pr-4 pt-4 font-size-lg color-black">
                      {tag}
                    </Tag>
                  ) : null,
                )}
              </div>

              {/** Description */}
              <div className="font-size-lg pt-6">{description}</div>
              {!!allDescriptionValues?.length && (
                <>
                  <div style={{ whiteSpace: 'pre-line' }}>
                    <TextField>{`${allDescriptionValues.slice(0, 2).join('. \n')}.`}</TextField>
                    {hasLongDescription && (
                      <Collapse isOpen={!isSummaryCollapsed}>
                        {`${allDescriptionValues.slice(2).join('. \n')}.`}
                      </Collapse>
                    )}
                  </div>
                  {hasLongDescription && (
                    <div
                      className="color-blue font-size-lg cursor-pointer"
                      onClick={() => setIsSummaryCollapsed((prev) => !prev)}
                    >
                      <span className="pr-2">
                        {isSummaryCollapsed ? t('Read more about the space') : t('Read less')}
                      </span>
                      {isSummaryCollapsed ? (
                        <Icon color="blue" icon="BtnArrowDown" height={10} width={10} />
                      ) : (
                        <Icon color="blue" icon="BtnArrowUp" height={10} width={10} />
                      )}
                    </div>
                  )}
                </>
              )}

              {/** Amenities */}
              {amenities.length > 0 && (
                <div className="d-flex flex-column pt-12">
                  <div className="font-size-21 pb-6">{t('Amenities')}</div>
                  <Amenities amenities={amenities.slice(0, AMENITIES_TO_SHOW)} isMobile={isMobile} />
                  {amenities.length > AMENITIES_TO_SHOW && (
                    <>
                      <Collapse isOpen={!isAmenitiesCollapsed}>
                        <Amenities amenities={amenities.slice(AMENITIES_TO_SHOW)} isMobile={isMobile} />
                      </Collapse>
                      <div
                        className="color-blue font-size-lg cursor-pointer"
                        onClick={() => setIsAmenitiesCollapsed((prev) => !prev)}
                      >
                        <span className="pr-2">
                          {isAmenitiesCollapsed ? `See all ${amenities.length} amenities` : 'See less'}
                        </span>
                        {isAmenitiesCollapsed ? (
                          <Icon color="blue" icon="BtnArrowDown" height={10} width={10} />
                        ) : (
                          <Icon color="blue" icon="BtnArrowUp" height={10} width={10} />
                        )}
                      </div>
                    </>
                  )}
                </div>
              )}

              {/* House Rules */}
              {showHouseRules && houseRules && (
                <div className="d-flex flex-column pt-12">
                  <div className="font-size-21 pb-4">{t('House rules')}</div>
                  <HouseRules houseRules={houseRules} />
                </div>
              )}

              {/** Location */}
              <div className="d-flex flex-column pt-12">
                <div className="font-size-21">{t('Location')}</div>
                <div className="font-size-lg pt-6">{locationDescription}</div>
                <MapWrapper>
                  <ListingMap position={{ lat, lng }} />
                </MapWrapper>
              </div>
            </div>
          </Listing>
        )}
      </Layout.ContainerFluid>

      <Layout.Footer fixed isHidden={isPageReadOnly}>
        <Footer
          expiration={expireAt}
          history={history}
          isMobile={isMobile}
          totalPrice={payoutValue}
          totalPriceCaption={payoutCaption}
          onClick={() => history.push(getInvoicePageUrl({ quotesId, listingId }))}
          isPaymentRequired={isPaymentRequired}
        />
      </Layout.Footer>
    </>
  );
};

ListingComponent.propTypes = {
  push: PropTypes.func.isRequired,
  listing: PropTypes.objectOf(),
  quotes: PropTypes.objectOf(),
};

const mapStateToProps = (state) => ({
  breakpoint: state.layout.breakpoint,
  listing: state.quotes.listing,
  inquiry: state.quotes.inquiry,
  quotes: state.quotes.quotes,
});

export default connect(mapStateToProps, null)(ListingComponent);
